@import './assets/styles/reset',
'./assets/styles/variables',
'./assets/styles/typography',
'./assets/styles/common',
'./assets/styles/transitions';

* {
    font-family: 'Avenir Next';
    color: $grey-1;
    font-size: 14px;
}
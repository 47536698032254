@font-face {
    font-family: 'Avenir Next';
    font-weight: normal;
    src: url('../fonts/AvenirNext-Regular.ttf'),
        url('../fonts/AvenirNext-Regular.woff'),
        url('../fonts/AvenirNext-Regular.woff2');
}

@font-face {
    font-family: 'Avenir Next';
    font-weight: 600;
    src: url('../fonts/AvenirNext-DemiBold.ttf'),
        url('../fonts/AvenirNext-DemiBold.woff'),
        url('../fonts/AvenirNext-DemiBold.woff2');
}

@font-face {
    font-family: 'Avenir Next';
    font-weight: bold;
    src: url('../fonts/AvenirNext-Bold.ttf'),
        url('../fonts/AvenirNext-Bold.woff'),
        url('../fonts/AvenirNext-Bold.woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;800;900&display=swap');

h1 {
    font-weight: bold;
    font-size: 32px;
}

h2 {
    font-weight: 600;
    font-size: 25px;
}

h3 {
    font-weight: 600;
    font-size: 18px;
}

h4 {
    font-weight: 600;
    font-size: 16px;
}

h5 {
    font-weight: 600;
    font-size: 14px;
}

h6 {
    font-weight: 600;
    font-size: 12px;
}

h5,
h6 {
    color: $BPI-Blue;
    text-transform: uppercase;
}

.big {
    font-size: 16px;
}

.small {
    font-size: 12px;
}

a,
button,
span.customLink {
    color: $BPI-Blue;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: .2s ease-in-out;

    &:hover {
        color: white;
        background-color: $BPI-Blue;
    }
}

button {
    background: none;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
    display: inline;
    padding: 0;

    &.primary,
    &.secondary,
    &.tertiary,
    &.calendarBarButton,
    &.danger,
    &.warning,
    &.approve,
    &.cancel {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-transform: uppercase;
        padding: 10px 16px;

        &.inactive {
            color: $grey-5;
            background-color: $grey-7;
            pointer-events: none;
            cursor: not-allowed;
        }

        &.small {
            font-size: 12px;
            font-weight: normal;
            line-height: 10px;
            padding: 5px 8px;
            text-transform: unset;
        }

        &>svg {
            margin: 0 10px 0 0;
            transition: .2s ease-in-out;
        }
    }

    &.primary {
        background: rgba($BPI-Green, 0.2);
        color: $BPI-Green;

        &:hover {
            background: white;
        }
    }

    &.secondary {
        background: $BPI-Green;
        color: white;

        &>svg {
            fill: white;
        }

        &:hover {
            background: white;
            color: $BPI-Green;

            &>svg {
                fill: $BPI-Green;
            }
        }
    }

    &.tertiary {
        background: white;
        color: $BPI-Green;

        &:hover {
            background: $BPI-Green;
            color: white;
        }
    }

    &.danger {
        background: $Red;
        color: white;

        &:hover {
            background: $BPI-Yellow;
            color: $Red;
        }
    }

    &.approve {
        background: $BPI-Green;
        color: white;

        &:hover {
            color: $BPI-Green;
            background-color: white;
        }
    }

    &.warning {
        background: $Orange;
        color: white;

        &:hover {
            color: $Orange;
            background-color: white;
        }
    }

    &.cancel {
        background: white;
        color: $grey-7;

        &:hover {
            background: $grey-7;
            border-color: white;
            color: white;
        }
    }

    &.calendarBarButton {
        background: $BPI-Blue;
        color: white;

        &:hover {
            background: white;
            border-color: white;
            color: $BPI-Blue;
        }
    }

}

main,
.overlay {
    ul:not(.se-menu-list) {
        padding: 0;

        li {
            font-style: oblique;
            list-style-type: none;
            margin-left: 12px;
            position: relative;

            &::before {
                background-color: $BPI-Green;
                border-radius: 2px;
                content: '';
                height: 6px;
                left: -12px;
                position: absolute;
                top: 10px;
                width: 6px;
            }
        }
    }
}

blockquote {
    background: $grey-5 url('../images/quotes.svg') no-repeat 97% 90%;
    background-size: 48px;
    font-size: 18px;
    margin: 0 0 48px;
    padding: 5% 15%;
    position: relative;
    text-align: right;

    * {
        font-size: 18px;
    }

    &::before {
        content: '';
        background: url('../images/quotes.svg') no-repeat;
        background-size: 48px;
        height: 48px;
        left: 24px;
        position: absolute;
        top: 0;
        transform: rotate(180deg);
        width: 48px;
    }
}
$mobile-breakpoint: 1024px;

// Main Colors
$BPI-Blue: #4DBEEE;
$BPI-Blue-Hover: #00ABC8;
$BPI-Light-Blue: #50A0CA;
$BPI-Transparent-Blue: #EEF9FE;

// Accent Colors
$BPI-Gold: #C88400;
$BPI-Yellow: #F4B11B;
$Orange: #EE8922;
$Pink: #E64783;
// Error
$Red: #E11F27;

// Accent & Success
$BPI-Green: #6BBE4C;

$LOGIN-Secondary:#00ABC8;
$LOGIN-Light-Green: #CCE9EE;

// For Backgrounds, inputs, and lines
$grey-1: #333333;
$grey-2: #6D6E71;
$grey-3: #98989A;
$grey-4: #A7A9AC;
$grey-5: #BCBEC0;
$grey-6: #D1D3D4;
$grey-7: #DCDCDC;
$grey-8: #F5F5F5;
$grey-element: $grey-7;
$grey-element-hover: #999;
$aqua-900: #0292AC;

// animation timing and fill
$animation-long: .5s;
$animation-short: .3s;
$animation-overshoot: cubic-bezier(0, 1, .6, 1.25);
$animation-overshoot-out: cubic-bezier(.08, -0.31, .84, .21);

$gradients: (blue: #81D3FF $BPI-Light-Blue,
  // Client portal
  darkBlue:$BPI-Light-Blue #2A71CD,
  darkBlue2: #6D9AD4 #2A71CD,
  darkGreen: #61C997 #00A657,
  darkOrange: #FF984E #FE6225,
  golden: #F0B034 $BPI-Gold,
  green:#C1E056 $BPI-Green,
  guinda: #AC4D71 #7C3D55,
  lightBlue: #ACDBF3 #61C1F3,
  lightOrange: #FFC148 #F69A3C,
  lightYellow : #FFE471 #EACA42,
  pink: #FFC8E3 #CE9FB6,
  teal: #57CEE8 #24B0C3,
  yellow : #FFE471 $BPI-Yellow,
  // coach candidates hours
  hoursRemaining: $BPI-Light-Blue $BPI-Light-Blue,
  hoursUsed: #CD0236 #CD0236,
  // loading
  loadingGrey: $grey-4 $grey-3);
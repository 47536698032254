.zoom-away {
  &-appear {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity $animation-short ease-in,
      transform $animation-short ease-in;
  }

  &-appear-done {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $animation-short ease-in,
      transform $animation-short ease-in;
  }

  &-enter {
    opacity: 0;
    transform: scale(0);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

  &-enter-done {
    opacity: 1;
    transform: scale(1);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

  &-exit {
    transform: scale(1);
    opacity: 1;
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

  &-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

  &-exit-done {
    opacity: 0;
    transform: scale(0);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }
}

.swivel {
  &-enter {
    // transform: translateX(-100px);
    transform: rotateY(450deg);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

  &-enter-done {
    transform: rotateY(0deg);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }


  &-exit-active {
    transform: rotateY(450deg);
    transition: opacity $animation-short ease-in-out,
      transform $animation-short ease-in-out;
  }

}

.bubble {

  &-enter,
  &-appear {
    opacity: 0;
    filter: blur(20px);
    transform: scale(0) translateY(-24px);
    transition: $animation-long $animation-overshoot,
      filter $animation-long $animation-overshoot;
  }

  &-enter-done,
  &-appear-done {
    filter: blur(0px);
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity $animation-long $animation-overshoot,
      transform $animation-long $animation-overshoot,
      filter $animation-long $animation-overshoot;
  }

  &-exit-active {
    filter: blur(20px);
    opacity: 0;
    transform: scale(0) translateY(-24px);
    transition: opacity $animation-long $animation-overshoot-out,
      transform $animation-long $animation-overshoot-out,
      filter $animation-long $animation-overshoot-out;
  }
}

.fade {

  &-enter,
  &-appear {
    opacity: 0;
    transition: opacity $animation-long ease-out;

    &.short {
      transition: opacity $animation-short ease-out;
    }
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
    transition: opacity $animation-long ease-out;

    &.short {
      transition: opacity $animation-short ease-out;
    }
  }

  &-exit-active {
    opacity: 0;
    transition: opacity $animation-long ease-out;

    &.short {
      transition: opacity $animation-short ease-out;
    }
  }

}

.swishIn {

  &-enter,
  &-appear {
    opacity: 0;
    transform: translateX(-240px);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-exit-active {
    opacity: 1;
    transform: translateX(-240px);
    transition: opacity $animation-short ease-in, transform $animation-short ease-in;
  }

}

.draggableIn {

  &-enter,
  &-appear {
    opacity: 0;
    transform: translateX(-110%);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-exit-active {
    opacity: 1;
    transform: translateX(-110%);
    transition: opacity $animation-short ease-in, transform $animation-short ease-in;
  }

}

.draggableInReverse {

  &-enter,
  &-appear {
    opacity: 0;
    transform: translateX(110%);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-exit-active {
    opacity: 1;
    transform: translateX(110%);
    transition: opacity $animation-short ease-in, transform $animation-short ease-in;
  }

}

.slideDown {

  &-enter,
  &-appear {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(1);
    transition: opacity $animation-short ease-out, transform $animation-short ease-out;
  }

  &-exit-active {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(0);
    transition: opacity $animation-short ease-in, transform $animation-short ease-in;
  }

}